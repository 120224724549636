import React from 'react'
import Icon4 from '../Images/logo.jpg'

const SiteFooter = () => {
  return (
      <div sticky="top" className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © {new Date().getFullYear()} All rights reserved.
        <a href='/'><img to='home' exact='true' src={Icon4} width="50" height="30" alt="Logo"></img>
        </a>
      </div>        
  )
}

export default SiteFooter
