import React from 'react'
import SiteNavBar from './SiteNavBar'
import SiteVideo from './SiteVideo'
import ContactUs from './ContactUs'
import SiteFooter from './SiteFooter'
import Services from './Services'

const Home = () => {
    return (
        <>
            <SiteNavBar />
            
            <SiteVideo />
            <ContactUs />
            <Services />
            <SiteFooter />

        </>
    )
}

export default Home
