import React from 'react'
import { Element } from 'react-scroll'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image1 from '../Images/lift.jpg'
import Image2 from '../Images/equipment.jpg'
import Container from 'react-bootstrap/Container'


const Services = () => {
  return (
    <Container>
      <Element name="services" className="element text-white bg-danger fs-2 s-1" >
        Services
      </Element>
      <Card style={{ width: '100%' }}>
      
      <Row xs={1} md={2} className="g-4">
        {/* {Array.from({ length: 4 }).map((_, idx) => ( */}
          <Col >
            <Card>
              <Card.Body>
                <Card.Title>Tree Care</Card.Title>
                <Card.Text>
                  Tree trimming. Tree pruning.
                </Card.Text>
                <Card.Title>Stumps</Card.Title>
                <Card.Text>
                  Grinding and removal of stumps.
                </Card.Text>
                <Card.Img variant="top" src={Image1} width="50" height="300" alt="equip"/>
              </Card.Body>
            </Card>
          </Col>
          <Col >
            <Card>
              <Card.Body>
                <Card.Title>Land Clearing</Card.Title>
                <Card.Text>
                  Tree clean-up and removal.
                </Card.Text>
                <Card.Title>Emergency Tree Services</Card.Title>
                <Card.Text>
                  Removal of fallen or damaged trees. 
                </Card.Text>
                <Card.Img variant="top" src={Image2} width="50" height="300" alt="equip2"/>
              </Card.Body>
            </Card>
          </Col>
        {/* )) */}
      </Row>
    </Card>
    </Container>
  )
}

export default Services
