import React from 'react'
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { Element } from 'react-scroll';
import { FaFacebook, FaInstagram} from 'react-icons/fa';
import Container from 'react-bootstrap/Container'

const ContactUs = () => {
  return (
    <Container>
    <Element name="contact" className="element text-white bg-danger fs-2 s-1" >
      Contact Information
    </Element>
    <Card style={{ width: '100%' }}>
      <ListGroup className="d-grid gap-3 mb-3" >
      <Card.Title className="p-1" >Triple Tree Care services East Peoria and surrounding areas.</Card.Title>
      <Card.Title >Contact Us Today!</Card.Title>
        <Card.Text><i className="fa-solid fa-phone"></i>  1-309-712-6281</Card.Text>
        <Card.Text><i className="fa-solid fa-envelope"></i >  sales@tripletreecarellc.com</Card.Text>
      </ListGroup>
      <Card.Body>
      <Card.Text>
          <Card.Link><a href="https://m.facebook.com/people/Triple-Tree-Care/100057510804735/" target="_blank" rel='noreferrer' aria-label="Facebook"><FaFacebook size={25}/></a></Card.Link>
          <br></br>
          <br></br>
          <Card.Link><a href="https://www.instagram.com/tripletreecare?igsh=MTVlc3QyeTczNHI0ag==" target="_blank" rel='noreferrer' aria-label="Instagram"><FaInstagram size={25}/></a></Card.Link>
        </Card.Text>
      </Card.Body>
    </Card>
    </Container>
  )
}

export default ContactUs
