import React from 'react'
import '../App.css'
import './components.css'
// import Video from '../Images/shortvideo.mp4'
// import logo from '../Images/logo.jpg'
import { Container } from 'react-bootstrap'
// import ReactPlayer from 'react-player'

const SiteVideo = () => {
  return (
    // <Container className = "container-fluid sizer">
      <Container className='p-text'>
        
                <p >Triple Tree Care is a full-service tree care company with the tools to manage any obstacle you have.</p>
                <p>We take great care to protect your property, making it healthier and better looking than when we arrived!</p>
                
      {/* //   <ReactPlayer */}
      {/* //   url="https://tripletreecarellc.s3.us-east-2.amazonaws.com/shortvideo.mp4"
      //   playing={true}
      //   loop={true}
      //   muted={true}
      //   width="100%"
      //   height="auto"
      //   /> */}
      </Container>
    //   {/* <div align="center" to='/' exact='true' ><img src={logo} width="320" height="300" alt="Logo" /></div> */}
    //   {/* <div className="embed-responsive embed-responsive-16by9"> */}
    // {/* <video className="w-100" autoPlay muted>
    //   <source
    //     src={Video}
    //     type="video/mp4"
    //   />
    // </video> */}
    // {/* </div> */}
    // {/* </Container> */}
  );
}

export default SiteVideo
