import React, {useEffect} from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Images/logowphone.jpg'
import { Link, Events, scrollSpy } from 'react-scroll'
import '../index.css'
import Container from 'react-bootstrap/Container'



const SiteNavBar = () => {

  useEffect(() => {
    Events.scrollEvent.register('begin', (to, element) => {
      console.log('begin', to, element);
    });
    Events.scrollEvent.register('end', (to, element) => {
      console.log('end', to, element);
    });
    scrollSpy.update();
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);
  
    // Function to handle the activation of a link.
    const handleSetActive = (to) => {
      console.log(to);
    };

  return (
    <Container>
    <Navbar sticky="top" collapseOnSelect expand="lg" className="text-dark border border-danger py-0 container-fluid ">
      <Navbar.Brand to='/' exact='true' ><img src={logo} width="120" height="100" alt="Logo" /></Navbar.Brand>
      <Navbar.Text className="text-danger fs-2" > Call for a quote!  </Navbar.Text>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        <Nav activeKey="/home" onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}>
          <Nav.Item className="ms-auto ">
            <Nav.Link> <Link activeClass="active" to="contact" spy={true}
              smooth={true}
              offset={10}
              duration={500}
              onSetActive={handleSetActive} >Contact</Link></Nav.Link>
          </Nav.Item>
          <Nav.Item className="ms-auto">
            <Nav.Link> <Link activeClass="active" to="services" spy={true}
              smooth={true}
              offset={10}
              duration={500}
              onSetActive={handleSetActive} >Services</Link></Nav.Link>
          </Nav.Item>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
    </Container>
  );
}

export default SiteNavBar
